import { Box, Tooltip as MuiTooltip, styled, useTheme } from '@mui/material';
import type { TooltipProps as MuiTooltipProps, SxProps } from '@mui/material';
import { colors } from 'styles/theme/colors';

export type TooltipProps = Pick<
  MuiTooltipProps,
  | 'title'
  | 'sx'
  | 'children'
  | 'arrow'
  | 'disableInteractive'
  | 'followCursor'
  | 'id'
  | 'onClose'
  | 'onOpen'
  | 'placement'
  | 'slots'
  | 'slotProps'
> & {
  containerSx?: SxProps;
};

export const Tooltip = ({
  sx,
  arrow = false,
  title,
  children,
  placement,
  disableInteractive = false,
  containerSx,
  onOpen,
  onClose,
  slotProps,
  slots,
}: TooltipProps) => {
  const theme = useTheme();

  return (
    <MuiTooltip
      title={title}
      sx={{ marginTop: 0, ...sx }}
      arrow={arrow}
      onOpen={onOpen}
      onClose={onClose}
      placement={placement}
      disableInteractive={disableInteractive}
      slots={slots}
      slotProps={{
        arrow: { sx: { color: colors.black[100] } },
        tooltip: {
          sx: {
            background: colors.black[100],
            fontSize: theme.typography.bodyMedium.fontSize,
            lineHeight: theme.typography.bodyMedium.lineHeight,
            paddingX: theme.spacing(2),
            paddingY: theme.spacing(1),
          },
        },
        ...slotProps,
      }}
    >
      <Container sx={containerSx}>{children}</Container>
    </MuiTooltip>
  );
};

const Container = styled(Box)({
  display: 'inline-block',
});
