import { SnackbarContent } from 'notistack';
import { X } from '@phosphor-icons/react';
import { styled, IconButton } from '@mui/material';
import type { BaseProps } from '../types';

export const Base = ({ children, handleDismiss, baseSx }: BaseProps) => {
  return (
    <SnackbarContent role="alert">
      <Container data-testid="snackbar-container" sx={baseSx}>
        <Content>{children}</Content>
        <Close onClick={handleDismiss}>
          <X color="inherit" size={16} />
        </Close>
      </Container>
    </SnackbarContent>
  );
};

export const Container = styled('div')(({ theme }) => ({
  minWidth: 288,
  display: 'flex',
  justifyItems: 'space-between',
  padding: theme.spacing(3),
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  'svg path': {
    color: 'inherit',
    fill: 'currentColor',
  },
}));

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const Close = styled(IconButton)({
  borderRadius: 4,
  width: 24,
  height: 24,
  color: 'inherit',
  padding: 0,
  margin: 0,
  background: 'none',
  'svg path': {
    color: 'inherit',
  },
});
