import { styled } from '@mui/material';
import { Button } from 'components';
import { X } from '@phosphor-icons/react';
import { useNotification } from '../hooks';

export const CloseAll = () => {
  const { dismissNotification } = useNotification();

  return (
    <CloseAllButton onClick={() => dismissNotification()} variant="primary">
      <X />
    </CloseAllButton>
  );
};

const CloseAllButton = styled(Button)({
  position: 'absolute',
  bottom: 0,
  padding: 0,
  width: 32,
  minWidth: 32,
  aspectRatio: 1 / 1,
  left: 0,
  background: '#1a1a1a',
  borderRadius: '50%',
  transform: 'translate(calc(-100% - 12px), calc(0% - 14px))',
  cursor: 'pointer',
  pointerEvents: 'auto',
});
