import * as React from 'react';
import { Box, Skeleton, styled } from '@mui/material';
import { ErrorOverlay } from './ErrorOverlay';
import type { SxProps } from '@mui/material';
import type { ImageProps } from './types';

export const Image = ({
  sx: sxProp,
  src,
  width,
  height,
  aspectRatio,
  alt,
  loading: loadingProp = 'lazy',
  isLoading: isLoadingProp,
  fit = 'contain',
  renditions,
  sizes,
  role,
}: ImageProps) => {
  const sx: SxProps = React.useMemo(
    () => ({
      ...sxProp,
      width,
      height: aspectRatio ? 'auto' : height,
      aspectRatio,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [sxProp, width, height]
  );

  const [imgError, setImgError] = React.useState<Error | null>(null);
  const [imgLoading, setImgLoading] = React.useState(false);

  const srcSet = React.useMemo(() => {
    return Object.entries(renditions || {})
      .map(rendition => {
        const keyAsWidth = parseInt(rendition[0]);
        const renditionSrc = rendition[1];
        if (!Number.isInteger(keyAsWidth)) return null;
        return `${renditionSrc} ${keyAsWidth}w`;
      })
      .filter(Boolean)
      .join(',');
  }, [renditions]);

  const handleLoad = React.useCallback(() => {
    setImgLoading(false);
  }, []);

  const handleError = React.useCallback(() => {
    setImgError(new Error(`Failed to load image "${src}"`));
    setImgLoading(false);
  }, [src]);

  React.useEffect(() => {
    setImgLoading(true);
    setImgError(null);
  }, [src]);

  const isLoading = isLoadingProp || imgLoading;

  return (
    <Box sx={{ maxWidth: '100%', position: 'relative', ...sx }}>
      {imgError ? <ErrorOverlay error={imgError} /> : null}
      {isLoading && !imgError ? (
        <Skeleton
          data-testid="skeleton-loader"
          variant="rectangular"
          width={width}
          height={aspectRatio ? 'auto' : height}
          sx={{
            aspectRatio,
          }}
        />
      ) : null}
      <Img
        data-testid="image-tag"
        loading={loadingProp}
        src={src}
        alt={alt}
        onLoad={handleLoad}
        onError={handleError}
        srcSet={srcSet}
        sizes={sizes}
        role={role}
        sx={{
          objectFit: fit,
          visibility: isLoading || imgError ? 'hidden' : 'visible',
        }}
      />
    </Box>
  );
};

const Img = styled('img')({
  width: '100%',
  height: '100%',
  position: 'absolute',
});
