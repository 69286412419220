interface TypographyProps {
  bodyLarge: React.CSSProperties;
  bodyMedium: React.CSSProperties;
  bodySmall: React.CSSProperties;
  bodyTiny: React.CSSProperties;

  headingLarge: React.CSSProperties;
  headingMedium: React.CSSProperties;
  headingSmall: React.CSSProperties;
}

declare module '@mui/material/styles' {
  interface TypographyVariants extends TypographyProps {}
  interface TypographyVariantsOptions extends TypographyProps {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends TypographyProps {
    body1: false;
    body2: false;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    bodyTiny: true;
    headingLarge: true;
    headingMedium: true;
    headingSmall: true;
  }
}

// Exact values has to be discussed with UX/Design team
export const typography = {
  fontFamily: [
    'Open Sans',
    'system-ui',
    '-apple-system',
    'Segoe UI',
    'Roboto',
    'Ubuntu',
    'Cantarell',
    'Noto Sans',
  ].join(','),
  htmlFontSize: 16,
  // Headings
  headingLarge: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
  },
  headingMedium: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
  },
  headingSmall: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
  // Body
  bodyLarge: {
    fontSize: '1.5rem',
    lineHeight: '1.25rem',
  },
  bodyMedium: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  bodySmall: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  bodyTiny: {
    fontSize: '0.5rem',
    lineHeight: '0.75rem',
  },
};
