import { useMutation, useQuery } from '@tanstack/react-query';
import type { UseMutationOptions, UndefinedInitialDataOptions } from '@tanstack/react-query';
import { useCheckAccess } from 'features/accessControl';
import type { ApiResponse } from 'apisauce';
import { t } from '@lingui/macro';
import { api, handleRequest } from '../utils';

const noAccessData = {
  Error: t`No Access`,
  Message: t`You do not have write access to the requested page.`,
};
const noAccessPromise = () =>
  Promise.resolve({
    ok: true,
    data: noAccessData,
  } as ApiResponse<typeof noAccessData>);

/**
 * @param url - The url to fetch data from
 * @param key - queryKey forwarded to useQuery
 * @param revalidate - By default we cache data, setting this to true will always refetch the data
 * @param options - Query options forwarded to useQuery
 * @returns a useQuery hook
 */
export function useGet<T>(
  url: string,
  key: string,
  revalidate: boolean = false,
  options: Omit<UndefinedInitialDataOptions<T>, 'queryKey'> = {}
) {
  return useQuery<T>({
    ...options,
    queryKey: [key],
    queryFn: () => handleRequest(api.get(url)),
    ...(revalidate ? { staleTime: 0 } : {}),
  });
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export function usePost<T>(url: string, options: UseMutationOptions = {}) {
  const hasAccess = useCheckAccess(true);
  return useMutation({
    ...options,
    mutationFn: hasAccess && url ? data => api.post<T>(url, data) : () => noAccessPromise(),
  });
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export function usePut<T>(url: string, options: UseMutationOptions = {}) {
  const hasAccess = useCheckAccess(true);
  return useMutation({
    ...options,
    mutationFn: hasAccess && url ? data => api.put<T>(url, data) : () => noAccessPromise(),
  });
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export const useDelete = (url: string, options: UseMutationOptions = {}) => {
  const hasAccess = useCheckAccess(true);
  return useMutation({
    ...options,
    mutationFn: hasAccess && url ? () => api.delete(url) : () => noAccessPromise(),
  });
};

/**
 * @param get - The url to fetch data from
 * @param key - queryKey forwarded to useQuery
 * @param put - The url to put data to
 * @param post - The url to post data to
 * @param remove - The url to delete data from
 * @param revalidate - By default we cache data, setting this to true will always refetch the data
 * @returns an object of hooks { get, create, update, remove }
 */
export const useApi = (
  get: string,
  key: string,
  put: string = '',
  post: string = '',
  remove: string = '',
  revalidate: boolean = false
) => {
  return {
    get: useGet(get, key, revalidate),
    create: usePut(put),
    update: usePost(post),
    remove: useDelete(remove),
  };
};
