import { DOMAIN } from 'config';

export const pathConverter = (path: string = '', accessToken: string = '') => {
  return `${DOMAIN}/?accessToken=${accessToken}&mode=headerless#${path}`;
};

export const iframePaths = [
  '/taskboard',
  '/style',
  '/item',
  '/report',
  '/dashboard',
  '/analytics',
  '/marketing',
  '/order',
  '/compliance',
  '/admin',
];
