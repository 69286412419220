import { styled } from '@mui/material';
import { TaskBarItem } from './TaskBarItem';
import { useStoreState } from 'stores/global';
import { useMemo } from 'react';
import { Stack } from 'components';

export const TaskBar = () => {
  const { modals, isModalListVisible } = useStoreState(state => state.modalManager);

  const visibility = useMemo(() => {
    return {
      opacity: isModalListVisible ? 1 : 0,
      transform: isModalListVisible ? 'translateY(0%)' : 'translateY(100%)',
    };
  }, [isModalListVisible]);

  return (
    <List data-testid="taskbar" style={visibility} direction="row">
      {modals?.map(modal => <TaskBarItem key={modal.id} {...modal} />)}
    </List>
  );
};

const List = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  minHeight: 42,
  gap: theme.spacing(1),
  bottom: 0,
  left: 0,
  zIndex: 2000,
  padding: theme.spacing(1),
  background: theme.palette.surface.main,
  width: '100%',
  transition: 'ease-out 250ms',
  overflowX: 'scroll',
  '::-webkit-scrollbar': { display: 'none' },
  scrollbarWidth: 'none',
}));
