import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { theme } from 'styles/theme/theme';

export type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
