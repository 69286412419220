import { SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback, useMemo } from 'react';
import { Typography, useTheme } from '@mui/material';
import type { CustomContentProps } from 'notistack';
import { Base } from './Base';

export type SnackbarProps = CustomContentProps;
export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  ({ id, message, variant, iconVariant }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    // Variants of the default snackbar, customized
    // snackbars should be put in different files
    const styles = useMemo(() => {
      switch (variant) {
        case 'info':
          return {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          };
        case 'warning':
          return {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          };
        case 'success':
          return {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
          };
        case 'error':
          return {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          };

        default:
          return {
            backgroundColor: theme.palette.surface.main,
            color: theme.palette.text.primary,
          };
      }
    }, [variant]);

    return (
      <SnackbarContent ref={ref} role="alert">
        <Base baseSx={{ ...styles }} handleDismiss={handleDismiss}>
          {iconVariant[variant]}
          <Typography variant="bodyMedium" mr={2}>
            {message}
          </Typography>
        </Base>
      </SnackbarContent>
    );
  }
);

Snackbar.displayName = 'Snackbar';
