import { Global, css } from '@emotion/react';
import { colors } from './theme/colors';

export const GlobalStyles = () => {
  return <Global styles={styles} />;
};

const styles = css`
  main,
  body {
    background-color: #ffffff;
  }

  a {
    color: ${colors.black[500]};
    text-decoration: none;

    &:hover {
      text-decoration: underlined;
    }
  }

  ul {
    margin: 0;
    padding-left: 0;
  }

  /* Hide Sentry marketing in Dialog component */
  .powered-by {
    display: none;
  }
`;
